import React from "react";
import { useRouter } from "next/router";
import {
  makeStyles,
  Grid,
  Container,
  Paper,
  Typography,
} from "@material-ui/core";
import SearchAutocomplete from "components/SearchAutocomplete";
import PriceFields from "./PriceFields";

const useStyles = makeStyles((theme) => ({
  form: {
    margin: "80px 0 0 96px",
  },
  box: {
    height: "265px",
    background: "#F7F7F8",
    border: "1px solid #E9ECEE",
    position: "relative",
  },
  title: {
    textAlign: "left",
    textTransform: "none",
    fontSize: "24px",
    lineHeight: "33px",
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: "30px",
    color: "#2E323C",
  },
  autocomplete: {
    "& .MuiInputBase-root": {
      background: "white",
      border: "solid 1px #eFeFeF",
    },
  },
  separator: {
    margin: "0",
    fontSize: "18px",
    textAlign: "center",
  },
  carImage: {
    height: "327px",
    width: "auto",
    position: "absolute",
    bottom: "165px",
    right: "45px",
  },
  "@media (max-width: 1024px)": {
    carImage: {
      height: "260px",
    },
  },
  "@media (max-width: 959px)": {
    form: {
      margin: "0",
      padding: "0 19px",
      "& .MuiGrid-root.MuiGrid-item": {
        textAlign: "center",
      },
    },
    container: {
      padding: "0",
    },
    box: {
      borderRadius: "0",
      height: "379px",
      border: "none",
      padding: "31px 0 42px 0",
    },
    title: {
      fontSize: "22px",
      textAlign: "center",
      lineHeigh: "48px",
      margin: "0 0 20px 0",
    },
    separator: {
      margin: "10px 19px",
      textAlign: "center",
    },
    carImage: {
      display: "none",
    },
  },
}));

const SearchForm = () => {
  const classes = useStyles();
  const router = useRouter();

  const handleOptionSelect = ({ type, model, brand }) => {
    const queryObject = {
      marca: [brand],
    };

    if (type === "modelo") {
      queryObject[type] = [model];
    }

    router.push({ pathname: "/catalogo", query: queryObject });
  };

  return (
    <Container className={classes.container}>
      <Paper elevation={0} className={classes.box}>
        <form className={classes.form}>
          <Typography variant="h2" className={classes.title}>
            Busque o veículo ideal para seu negócio
          </Typography>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={12} md={4} style={{ position: "relative" }}>
              <SearchAutocomplete
                label="Digite a marca ou modelo"
                onSelect={handleOptionSelect}
                className={classes.autocomplete}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={1}>
              <div className={classes.separator}>
                <span>ou</span>
              </div>
            </Grid>
            <PriceFields />
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default SearchForm;

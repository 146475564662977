import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import BannerSlider from "./BannerSlider";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const HeroBanner = () => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <BannerSlider />
    </Container>
  );
};

export default HeroBanner;

import { instance } from "api";
import { useQuery } from "react-query";

export const getPrices = () =>
  instance
    .post(`/search/revenda`, {
      size: 0,
      aggs: {
        max_price: {
          max: { field: "price" },
        },
        min_price: {
          min: { field: "price" },
        },
      },
    })
    .then((res) => res.data.aggregations);

export const useSearchPriceRange = () => {
  const searchPriceRange = useQuery(["searchPriceRange"], getPrices);

  return searchPriceRange;
};

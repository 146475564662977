import React from "react";
import { Container, useMediaQuery, makeStyles } from "@material-ui/core";
import HowItWorksCard from "./HowItWorksCard";
import { Subtitle } from "components";
import LoopSlider from "components/LoopSlider";

const useStyles = makeStyles({
  section: {
    padding: "40px 0 40px",
    backgroundColor: "#E9ECEE",
    marginBottom: "-24px",
    "& h2": {
      color: "#1E2126",
      marginBottom: "43px",
    },
  },
  curve: {
    transform: "rotate(-96deg)",
    position: "absolute",
    right: "20%",
    top: "-66px",
  },
  "@media (max-width: 730px)": {
    section: {
      padding: "0 0 36px",
      "& h2": {
        fontSize: "24px",
        paddingTop: "18px",
      },
    },
    curve: {
      transform: "rotate(-3deg)",
      position: "absolute",
      right: "45%",
      top: "-70px",
    },
    title: {
      paddingTop: "80px",
    },
  },
});

const cardList = [
  {
    title: "Veículos",
    icon: "/img/revenda/veiculo.svg",
    text: "Todos os veículos disponíveis não possuem marcação de leilão e ficam liberados para oferta pelo período limitado informado no anúncio.",
  },
  {
    title: "Envie sua oferta",
    icon: "/img/revenda/oferta.svg",
    text: `Toda compra é realizada através do envio de ofertas onde a maior oferta passará por uma condicional (análise) do comitente e poderam ser aprovadas ou rejeitadas. Para garantir a compra imediata você pode enviar a opção de oferta “COMPRE JÁ”.`,
  },
  {
    title: "Cancelamento",
    icon: "/img/revenda/cancelamento.svg",
    text: "Toda oferta enviada é considerada uma proposta de compra, não sendo possível o cancelamento. Conforme condições gerais.",
  },
];

const HowItWorks = () => {
  const classes = useStyles();
  const mobile = useMediaQuery("(max-width: 960px)");
  const slidesToShow = mobile ? 1 : 3;

  return (
    <div className={classes.section}>
      <Container style={{ position: "relative" }}>
        <Subtitle>Como Funciona</Subtitle>
        <LoopSlider settings={{ dots: true, slidesToShow, arrows: false }}>
          {cardList.map((item) => (
            <HowItWorksCard
              key={item.title}
              icon={<img src={item.icon} alt="" />}
              title={item.title}
              description={item.text}
            />
          ))}
        </LoopSlider>
      </Container>
    </div>
  );
};

export default HowItWorks;

import { useQuery } from "react-query";
import { Keys } from "constants/Keys";
import { postSearchVehicle } from "../api/searchVehicle";

export const useFeaturedGroups = () => {
  const featuredGroups = useQuery(Keys.getFeaturedGroups(), () =>
    postSearchVehicle({
      size: 0,
      aggs: {
        filtered_group: {
          filter: {
            bool: { must_not: { match: { "group.label": "Revenda" } } },
          },
          aggs: { group: { terms: { size: 4, field: "group.label" } } },
        },
      },
    }).then((res) => res?.aggregations?.filtered_group)
  );

  return featuredGroups;
};

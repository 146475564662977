import { makeStyles } from "@material-ui/core";
import LoopSlider from "../LoopSlider";
import { Autoplay, Pagination } from "swiper";
import "swiper/css/autoplay";
import Banner from "./Banner";
import { useBanners } from "@/Home/api/useBanners";

const useStyles = makeStyles((theme) => ({
  slider: {
    zIndex: 0,
    "& .swiper-wrapper": {
      paddingBottom: "unset",
    },
    "& .swiper-pagination": {
      right: theme.spacing(4),
      left: "unset",
      background: "unset",
      border: "unset",
      bottom: theme.spacing(4),
    },
    "& .swiper-pagination-bullet": {
      backgroundColor: "transparent",
      border: `2px solid ${theme.palette.primary.light}`,
    },
    "& .swiper-pagination-bullet-active": {
      backgroundColor: theme.palette.primary.main,
    },
    "& .slick-dots li.slick-active button:before": {
      border: `1px solid ${theme.palette.primary.light}`,
    },
  },
}));

const BannerSlider = () => {
  const classes = useStyles();
  const banners = useBanners();

  const settings = {
    rewind: true,
    autoplay: { delay: 10000 },
    slidesPerView: 1,
    modules: [Pagination, Autoplay],
    pagination: { clickable: true },
  };

  if (banners.isLoading || banners.isError || banners.isIdle) {
    return null;
  }

  return (
    <LoopSlider settings={settings} className={classes.slider}>
      {banners.data.banners_rev.map((banner) => (
        <Banner key={banner.id} {...banner} />
      ))}
    </LoopSlider>
  );
};

export default BannerSlider;

import React from "react";
import { makeStyles, useTheme } from "@material-ui/core";
import SearchForm from "./SearchForm";
import { Curve } from "components/icon";
import HeroBanner from "./HeroBanner";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "40px",
    width: "100%",
    position: "relative",
  },
  content: {
    margin: "60px 0 30px 0",
    display: "inline-block",
    "& h1": {
      fontSize: "26px",
      lineHeight: "34px",
      color: "#1E2126",
      padding: "0",
      margin: "0",
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  text: {
    fontSize: "40px",
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: "left",
    letterSpacing: "0px",
    lineHeight: "52px",
    display: "block",
    color: "#1E2126",
  },
  curve: {
    transform: "rotate(-96deg)",
    zIndex: 2,
    position: "absolute",
    right: "20%",
    bottom: "-66px",
  },
  "@media (min-width: 730px)": {
    curve: {
      transform: "rotate(-90deg)",
      position: "absolute",
      right: "25%",
      bottom: "-70px",
    },
  },

  "@media (min-width: 1025px)": {
    content: {
      margin: "105px 0 105px 0",
      "& h1": {
        fontSize: "48px",
        lineHeight: "62px",
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    text: {
      fontSize: "26px",
      lineHeight: "30px",
      display: "inline-table",
      padding: "15px",
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
}));

const Hero = () => {
  const classes = useStyles();
  const theme = useTheme();
  const colorCurve = theme.mixins.hexAlpha(theme.palette.primary.main, 0.5);

  return (
    <div className={classes.root}>
      <HeroBanner />

      <SearchForm />
      <Curve
        className={classes.curve}
        color={colorCurve}
        width="54px"
        height="54px"
      />
    </div>
  );
};

export default Hero;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

const useStyles = makeStyles((theme) => ({
  slider: {
    overflow: "hidden",
    "& .swiper-wrapper": {
      paddingBottom: theme.spacing(6),
    },
    "& .swiper-pagination": {
      bottom: "0px",
      right: "0px",
      left: "0px",
      margin: "auto",
      width: "fit-content",
      padding: theme.spacing(0.5, 1),
      background: "white",
      borderRadius: theme.spacing(2),
      border: "1px solid " + theme.palette.grey[200],
    },
    "& .swiper-pagination-bullet": {
      width: theme.spacing(1),
      height: theme.spacing(1),
      background: theme.palette.grey[400],
      margin: theme.spacing(0, 1) + " !important",
    },
    "& .swiper-pagination-bullet-active": {
      background: theme.palette.primary.light,
    },
  },
  [theme.breakpoints.up("md")]: {
    slider: {
      "& .swiper-pagination-bullet": {
        width: "10px",
        height: "10px",
      },
    },
  },
}));

const LoopSlider = ({ children, settings, className }) => {
  const classes = useStyles();

  const localSettings = {
    ...settings,
  };

  return (
    <Swiper {...localSettings} className={`${classes.slider} ${className}`}>
      {children.map((child, index) => (
        <SwiperSlide key={index}>{child}</SwiperSlide>
      ))}
    </Swiper>
  );
};

export default LoopSlider;

import React, { useState } from "react";
import { Button, Grid, makeStyles, TextField } from "@material-ui/core";
import { useRouter } from "next/router";
import InputFormatCurrency from "ui/Input/InputFormatCurrency";
import { useSearchPriceRange } from "../api/searchPriceRange";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  input: {
    "& .MuiInputBase-root": {
      background: "white",
      border: "solid 1px #eFeFeF",
    },
  },
  button: {
    marginTop: "16px",
  },
  [theme.breakpoints.up("md")]: {
    input: {
      marginRight: "16px",
    },
    button: {
      marginTop: "0",
    },
  },
}));

const PriceFields = () => {
  const classes = useStyles();
  const { data: priceRange } = useSearchPriceRange({
    onSuccess: (data) => {
      setValue([data.min_price.value, data.max_price.value]);
    },
  });
  const min = priceRange?.min_price?.value;
  const max = priceRange?.max_price?.value;
  const [value, setValue] = useState([min, max]);
  const router = useRouter();

  useEffect(() => {
    setValue([min, max]);
  }, [min, max]);

  const handleSearchSubmit = () => {
    const query = {
      preco: value,
    };

    router.push({ pathname: "/catalogo", query });
  };

  const handleInputChange = (event) => {
    const value = Number(event.target.value);
    if (event.target.name === "min") {
      return setValue((state) => [value, state[1]]);
    }

    setValue((state) => [state[0], value]);
  };

  const handleBlur = () => {
    let newValue = value;

    if (Number.isNaN(value[0])) {
      newValue = [min, value[1]];
    }

    if (Number.isNaN(value[1])) {
      newValue = [value[0], max];
    }

    if (value[0] < min) {
      newValue = [min, newValue[1]];
    }

    if (value[0] > max) {
      newValue = [value[1], max];
    }

    if (value[1] > max) {
      newValue = [value[0], max];
      setValue((state) => [state[0], max]);
    }

    if (value[1] < min) {
      newValue = [min, value[0]];
    }

    newValue = [Math.min(...newValue), Math.max(...newValue)];
    setValue(newValue);
  };

  return (
    <>
      <Grid item xs={6} md={2}>
        <TextField
          id="preco_minimo"
          name="min"
          value={value[0]}
          onChange={handleInputChange}
          onBlur={handleBlur}
          className={classes.input}
          variant="filled"
          label="Valor Mínimo"
          InputProps={{
            inputComponent: InputFormatCurrency,
          }}
        />
      </Grid>
      <Grid item xs={6} md={2}>
        <TextField
          id="preco_maximo"
          name="max"
          value={value[1]}
          onChange={handleInputChange}
          onBlur={handleBlur}
          className={classes.input}
          variant="filled"
          label="Valor Máximo"
          InputProps={{
            inputComponent: InputFormatCurrency,
          }}
        />
      </Grid>
      <Grid container item xs={12} md={3} alignItems="center">
        <Button
          className={classes.button}
          onClick={handleSearchSubmit}
          variant="contained"
          color="primary"
        >
          Buscar
        </Button>
      </Grid>
    </>
  );
};

export default PriceFields;

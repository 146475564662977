import React from "react";
import { makeStyles } from "@material-ui/core";
import { Paper, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      border: "1px solid #E9ECEE",
      borderRaidius: theme.shape.borderRadius,
      background: "#FFFFFF",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      padding: "70px",
    },
  },
  icon: {
    marginBottom: "40px",
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: "6px",
  },
  description: {
    marginBottom: "16px",
  },
  "@media (max-width: 959px)": {
    root: {
      "& > *": {
        padding: "35px",
      },
    },
    icon: {
      marginBottom: "35px",
    },
    description: {
      fontSize: "16px",
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
}));

const AlertMessage = ({ icon, title, message, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper elevation={0}>
        {icon && <div className={classes.icon}>{icon}</div>}
        <Typography variant="h2" className={classes.title}>
          {title}
        </Typography>
        <Typography variant="h4" className={classes.description}>
          {message}
        </Typography>
        {children}
      </Paper>
    </div>
  );
};
export default AlertMessage;

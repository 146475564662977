import { useQuery } from "react-query";
import { postSearchVehicle } from "../api/searchVehicle";

export const useFeaturedVehicles = () => {
  const featuredVehicles = useQuery(["featuredVehicles"], () =>
    postSearchVehicle({
      from: 0,
      size: 4,
      query: {
        bool: {
          must: [
            {
              terms: {
                "group.label": [
                  "Revenda",
                  "Club Platinum Santander",
                  "Platinum Santander",
                ],
              },
            },
          ],
          filter: [{ range: { "inspection.mileage.value": { gte: 0 } } }],
        },
      },
    }).then((res) => res?.hits?.hits)
  );

  return featuredVehicles;
};

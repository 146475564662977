import { makeStyles } from "@material-ui/core";
import Link from "next/link";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
});

const BannerImages = ({
  mobile,
  mobileLarge,
  desktop,
  desktopLarge,
  description,
}) => {
  const classes = useStyles();

  const src = (url) => `${process.env.NEXT_PUBLIC_CONTENT_MANAGER}${url}`;

  return (
    <picture draggable={false}>
      <source
        media="(min-width: 1200px)"
        srcSet={src(desktopLarge.url)}
        draggable={false}
      />
      <source
        media="(min-width: 600px)"
        srcSet={src(desktop.url)}
        draggable={false}
      />
      <source
        media="(min-width: 380px)"
        srcSet={src(mobile.url)}
        draggable={false}
      />
      <img
        loading="lazy"
        src={src(mobileLarge.url)}
        alt={description || ""}
        draggable={false}
        className={classes.root}
      />
    </picture>
  );
};

const Banner = ({
  link,
  desktop,
  desktopLarge,
  mobile,
  mobileLarge,
  description,
}) => {
  if (!link) {
    return (
      <BannerImages
        desktop={desktop}
        desktopLarge={desktopLarge}
        mobile={mobile}
        mobileLarge={mobileLarge}
        description={description}
      />
    );
  }

  if (link.includes("https"))
    return (
      <a href={link}>
        <BannerImages
          desktop={desktop}
          desktopLarge={desktopLarge}
          mobile={mobile}
          mobileLarge={mobileLarge}
          description={description}
        />
      </a>
    );

  return (
    <Link href={link}>
      <a>
        <BannerImages
          desktop={desktop}
          desktopLarge={desktopLarge}
          mobile={mobile}
          mobileLarge={mobileLarge}
          description={description}
        />
      </a>
    </Link>
  );
};

export default Banner;

import { Container, Grid, makeStyles } from "@material-ui/core";
import { Subtitle } from "components";
import { LOYALTY_PROGRAM_GROUPS } from "constants/groups";
import { useAuth } from "hooks/useAuth";
import { useFeaturedGroups } from "../hooks/useFeaturedGroups";
import ExclusiveOffer from "./ExclusiveOffer";

const useStyles = makeStyles({
  root: {
    marginBottom: "40px",
  },
});

const checkUserGroups = (featuredGroups, session) => {
  let userIsInFeaturedGroup = false;
  const userFeaturedGroups = [];

  featuredGroups?.data?.group?.buckets?.map(({ key }) => {
    if (LOYALTY_PROGRAM_GROUPS.includes(key)) {
      return null;
    }

    session?.groups?.forEach(({ label }) => {
      if (label === key) {
        userIsInFeaturedGroup = true;
        userFeaturedGroups.push(key);
      }
    });
  });

  return { userIsInFeaturedGroup, userFeaturedGroups };
};

const ExclusiveOfferList = () => {
  const classes = useStyles();
  const { session } = useAuth();
  const featuredGroups = useFeaturedGroups();
  const shouldDisplayExclusive = featuredGroups?.data?.doc_count > 0;
  const userIsInLoyaltyGroup = featuredGroups?.data?.group?.buckets?.some(
    ({ key }) => LOYALTY_PROGRAM_GROUPS.includes(key)
  );

  const { userIsInFeaturedGroup, userFeaturedGroups } = checkUserGroups(
    featuredGroups,
    session
  );

  return (
    <>
      {shouldDisplayExclusive ? (
        <Container className={classes.root}>
          {(userIsInLoyaltyGroup || userIsInFeaturedGroup) && (
            <Subtitle>Ofertas Exclusivas</Subtitle>
          )}
          <Grid container direction="row" alignItems="center" spacing={2}>
            {userIsInLoyaltyGroup && (
              <Grid item md={3}>
                <ExclusiveOffer
                  name={LOYALTY_PROGRAM_GROUPS.join("&grupo=")}
                  label="+Fidelidade"
                />
              </Grid>
            )}
            {userFeaturedGroups?.map((key) => (
              <Grid item md={3} key={key}>
                <ExclusiveOffer name={key} />
              </Grid>
            ))}
          </Grid>
        </Container>
      ) : null}
    </>
  );
};

export default ExclusiveOfferList;

import { contentManagerInstance } from "api";
import { Keys } from "constants/Keys";
import { useQuery } from "react-query";

export const getBanners = async () =>
  contentManagerInstance.get("/banners-revenda").then((res) => res.data);

export const useBanners = () => {
  const banners = useQuery(Keys.getBanners(), getBanners, {
    refetchOnMount: false,
  });

  return banners;
};

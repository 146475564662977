export const Keys = {
  getBanners: () => ["getBanners"],
  getVehicle: (sku) => ["getVehicle", { id: sku }],
  getFreightSimulation: (sku, zipcode) => [
    "getFreightSimulation",
    { id: sku, zipcode: zipcode },
  ],
  getFeaturedGroups: () => ["getFeaturedGroups"],
  getOrdersByAd: (sku) => ["getOrdersByAd", { id: sku }],
  getAutomaticOffer: (sku) => ["getAutomaticOffer", { id: sku }],
  getBestOffer: (sku) => ["getBestOffer", { id: sku }],
  getCustomers: () => ["getCustomers"],
  getOrder: (uuid) => ["getOrder", { id: uuid }],

  postOffer: () => ["postOffer"],
  postAutomaticOffer: () => ["postAutomaticOffer"],
  postSearchSimilarVehicles: (sku, brand) => [
    "postSearchSimilarVehicles",
    { id: sku, brand: brand },
  ],
  postFreightSimulation: () => ["postFreightSimulation"],
  passwordRecovery: () => ["passwordRecovery"],
  postResendConfirmation: () => ["postResendConfirmation"],
  postConsent: () => ["postConsent"],

  putOrderCustomer: () => ["putOrderCustomer"],
};

import Link from "next/link";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "200px",
    border: "1px solid black",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.primary.contrastText,
    padding: "20px",
    display: "block",
    textDecoration: "none",
  },
  name: {
    fontSize: "32px",
  },
}));

const ExclusiveOffer = ({ name, label = "" }) => {
  const classes = useStyles();

  return (
    <Link href={`/catalogo?grupo=${name}`} passHref>
      <Typography variant="h3" component="a" className={classes.root}>
        Ver ofertas
        <div className={classes.name}>{label ? label : name}</div>
      </Typography>
    </Link>
  );
};

export default ExclusiveOffer;

import React from "react";
import BuyPage from "@/Home/pages/BuyPage";
import Head from "next/head";

const Buy = () => {
  return (
    <>
      <Head>
        <title>
          Para você lojista e concessionário que busca reposição de estoque e/ou
          canais alternativos para venda.
        </title>
      </Head>
      <BuyPage />
    </>
  );
};

export default Buy;

import React from "react";
import Hero from "../components/Hero";
import ExclusiveOfferList from "../components/ExclusiveOfferList";
import HowItWorks from "../components/HowItWorks";
import FeaturedList from "../components/FeaturedList";

const BuyPage = () => {
  return (
    <main>
      <Hero />
      <ExclusiveOfferList />
      <FeaturedList />
      <HowItWorks />
    </main>
  );
};

export default BuyPage;

import { instance } from "api";

export const getBrandAndModels = () =>
  instance.post(`/search/revenda`, {
    size: 0,
    aggs: {
      brands: {
        terms: { size: 10000, field: "brand.label" },
      },
      composite_brand_model: {
        composite: {
          size: 10000,
          sources: [
            { brand: { terms: { field: "brand.label" } } },
            { model: { terms: { field: "model.label" } } },
          ],
        },
      },
    },
  });

import React from "react";
import Slider from "react-slick";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  slider: {
    "& .slick-track": {
      display: "flex",
    },
    "& .slick-list": {
      overflow: "hidden",
    },
    "& .slick-prev, .slick-next": {
      background: "none center no-repeat #fff",
      border: "1px solid #E9ECEE",
      borderRadius: "50%",
      display: "block !important",
      height: "31px",
      width: "31px",
      zIndex: "1",
    },
    "& .slick-prev": {
      backgroundImage:
        'url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDIwIDIwIj4NCiAgPHBhdGggZD0iTTY2Ljg0OSwyODIuMTE0LDU4LjA3LDI5MC45bC04Ljc4NC04Ljc4N2EuNzIxLjcyMSwwLDAsMC0uNTA5LS4xOTRINDguNzdhLjcxMS43MTEsMCwwLDAtLjUuMjI4LjcyMy43MjMsMCwwLDAsLjAwNy45ODhsOS4yOTQsOS4yOTNhLjY1OS42NTksMCwwLDAsLjI0Mi4xMzYuNzA2LjcwNiwwLDAsMCwuMjc2LjA1NS43MTkuNzE5LDAsMCwwLC41LS4ybDkuMjc2LTkuMjhhLjcxNy43MTcsMCwwLDAsMC0xLjAxN0EuNzM3LjczNywwLDAsMCw2Ni44NDksMjgyLjExNFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDI5Ny4yNjMgLTQ4LjQwNCkgcm90YXRlKDkwKSIgZmlsbD0iIzFlMjEyNiI+PC9wYXRoPg0KPC9zdmc+DQo=")',
      left: "94%",
      top: "-50px",
    },
    "& .slick-next": {
      backgroundImage:
        'url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDIwIDIwIj4NCiAgICA8cGF0aCBkPSJNNjYuODQ5LDI4Mi4xMTQsNTguMDcsMjkwLjlsLTguNzg0LTguNzg3YS43MjEuNzIxLDAsMCwwLS41MDktLjE5NEg0OC43N2EuNzExLjcxMSwwLDAsMC0uNS4yMjguNzIzLjcyMywwLDAsMCwuMDA3Ljk4OGw5LjI5NCw5LjI5M2EuNjU5LjY1OSwwLDAsMCwuMjQyLjEzNi43MDYuNzA2LDAsMCwwLC4yNzYuMDU1LjcxOS43MTksMCwwLDAsLjUtLjJsOS4yNzYtOS4yOGEuNzE3LjcxNywwLDAsMCwwLTEuMDE3QS43MzcuNzM3LDAsMCwwLDY2Ljg0OSwyODIuMTE0WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTI3Ni45MTEgNjcuNzQ5KSByb3RhdGUoLTkwKSIgZmlsbD0iIzFlMjEyNiI+PC9wYXRoPg0KPC9zdmc+DQo=")',
      right: 0,
      top: "-50px",
    },
    "& .slick-disabled": {
      backgroundColor: "inherit",
      opacity: ".5",
    },
    "& .slick-dots": {
      alignItems: "center",
      backgroundColor: "#fff",
      border: "1px solid #E9ECEE",
      borderRadius: "14px",
      display: "flex !important",
      justifyContent: "space-between",
      marginLeft: "50%",
      marginTop: "20px",
      opacity: 1,
      overflow: "hidden",
      padding: "8px 16px",
      position: "relative",
      transform: "translateX(-50%)",
      width: "95px",
    },
    "& .slick-dots li": {
      alignItems: "center",
      display: "flex",
      height: "100%",
      justifyContent: "center",
      margin: 0,
      width: "25px",
    },
    "& .slick-dots li button": {
      alignItems: "center",
      border: "none",
      borderRadius: "50%",
      display: "flex",
      fontSize: "0",
      height: "12px",
      justifyContent: "center",
      width: "10px",
    },
    "& .slick-dots li button:before": {
      backgroundColor: "#E9ECEE",
      borderRadius: "50%",
      content: '""',
      height: "10px",
      left: "50%",
      opacity: 1,
      top: "12%",
      transform: "translateX(-50%) translateY(50%)",
      width: "10px",
    },
    "& .slick-dots li.slick-active button": {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

const LoopSlider = ({ children, settings, className }) => {
  const classes = useStyles();

  const localSettings = {
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    touchThreshold: 1000,
    swipeToSlide: true,
    ...settings,
  };

  return (
    <Slider {...localSettings} className={`${classes.slider} ${className}`}>
      {children}
    </Slider>
  );
};

export default LoopSlider;
